let _apimUrl = "";
let env = process.env.REACT_APP_ENV;


switch (env ) {
  case "local":
    _apimUrl = "https://localhost:49499";
    break;
  case "dev":
    // _apimUrl = "https://localhost:49499";
    _apimUrl = "https://apim.wildfireapps-dev.alberta.ca/wildfire-admin-api-dev";
    break;
  case "uat":
    _apimUrl = "https://goa-wdp-apim-uat-vnet.azure-api.net/goa-wdp-admin-api";
    break;
  case "prod":
    _apimUrl = "https://goa-wdp-apim.azure-api.net/goa-wdp-admin-api";
    break;
}

export const apimUrl = _apimUrl;
export const dateFormat = "YYYY-MM-DD";
export const tz = "America/Edmonton";
