import { GoATableSortDirection, GoAAccordion, GoAButton, GoAButtonGroup, GoACallout, GoACheckbox, GoACircularProgress, GoAIcon, GoAIconButton, GoAInputText, GoAModal, GoACalloutType } from "@abgov/react-components";

import './userRoles.scss'
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import WdpTable from "../../../components/wdpTable/WdpTable";
import { RoleInfo, RolePlanInfo } from "../../../common/models/roleInfo";
import apiService from "../../../services/ApiService";
import { wdpUtil } from "../../../common/utils/util";
import { CustomFmF } from "../../../components/datePicker/datePicker";
import { Toast } from "../../../components/toast/toast";
import { useAuth } from "react-oidc-context";
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { parseToken } from "../../../common/utils/tokenUtil";
import { AxiosError } from "axios";
import { tz } from "../../../common/constants";
import { RoleType } from "../../../common/enums";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(tz);

// enmum

export enum ModalType { none = 0, mainRolesCancel, grantedRolesCancel }

const width = 'minmax(min-content,min-content)';
const defSortDir: GoATableSortDirection = 'asc'
const grantedRolesTableHeaders = [
  { text: "Roles", name: 'name', width: `auto` },
  { text: "Granted start (required)", name: 'grantedStart', width: `270px` },
  { text: "Granted end", name: 'grantedEnd', width: `270px`, sortDir: defSortDir },
];
const rolesMainTableHeaders = [
  { text: "Selected", name: 'selected', width: `${width}` },
  ...grantedRolesTableHeaders
  //{ text: "type", name: 'grantedEnd', width: `240px`,sortDir: defSortDir },
];

export const UserRoles = (props: any) => {

  const auth = useAuth();

  // hooks
  const [isLoading, setIsLoading] = useState('');
  const [searchStr, setSearchStr] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [RolesGrantedList, setRolesGrantedList] = useState(Array<any>);
  const [RolesMainList, setRolesMainList] = useState(Array<RolePlanInfo>);
  const [editModeGranted, setEditModeGranted] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState('');
  const [toastType, setToastType] = useState<GoACalloutType | undefined>('success');
  const [openAccordionMain, setopenAccordionMain] = useState(false);
  const [rolesListPreview, setRolesListPreview] = useState(Array<RolePlanInfo>);
  const previewULWrapElement = useRef<HTMLUListElement | null>(null);
  const searchWrap = useRef<HTMLDivElement | null>(null);
  const [showModal, setShowModal] = useState(ModalType.none);

  /***************************************************************************************
   * 
   * data intilize load
  ****************************************************************************************/
  useEffect(() => {

    // first make sure this user is valid and has all his mandatory info in the backend
    if (wdpUtil.isNullorUndefined(props.currUser)) {
      // we shouldnot come here
      console.log("RoleTab: current user is not provided");
      return;
    }
    else {
      //query all Roles
      loadRoles('', 'main');
      // query  granted
      loadRoles('', 'granted');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currUser]);
  /***************************************************************************************
   * 
   * 
  ****************************************************************************************/
  useEffect(() => {
    //  GoA styling 
    GoAOverWrite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },);
  /***************************************************************************************
   * 
   * overwrite some GoA Components- styles
  ****************************************************************************************/
  function GoAOverWrite(){
    const page = document.getElementsByClassName('roles-content')[0];
    const accordions = page.querySelectorAll('goa-accordion');
    for (let i = 0; i < accordions.length; i++) {
      // we need the section key ..just to know which acc is this 
      const SecKey = accordions[i].parentElement?.getAttribute('data-key');
      // get the shado
      let shadow = accordions[i]?.shadowRoot;
      if (shadow?.children !== undefined) {
        //const hc1 = shadow.querySelectorAll('details')[0];
        // accordion element padding
        const contentElem = shadow?.querySelectorAll('.content')[0];
        if (contentElem !== undefined) {
          (contentElem as HTMLElement).style.padding = '0';
        }
        // summary elem
        const saummary = shadow?.querySelectorAll('summary')[0];
        if(!wdpUtil.isNullorUndefined(saummary)) {
          (saummary as HTMLElement).style.paddingTop = '0.7rem';
          (saummary as HTMLElement).style.backgroundColor = 'transparent';
          // is this is for the main accordios?
          if( !wdpUtil.isNullorUndefined(SecKey)  && SecKey === 'mainAccordionSec'){
            saummary.onclick= ()=> { setopenAccordionMain(!openAccordionMain)};
          }
        }
        // buttons height inside the accor
        const allBtn = accordions[i].querySelectorAll('goa-button');
        for (let i = 0; i < allBtn.length; i++) {
          let shadow = allBtn[i]?.shadowRoot;
          if (shadow?.children !== undefined) {
            const Btns = shadow?.querySelectorAll('button');
            for (let i = 0; i < Btns.length; i++) {
              (Btns[i] as HTMLElement).style.height = "auto";;
            }
          }
        }
        //  checkboxs
        const chkBtn = accordions[i].querySelectorAll("goa-checkbox");
        for (let i = 0; i < chkBtn.length; i++) {
          let shadow = chkBtn[i]?.shadowRoot;
          const chkBx = shadow?.querySelectorAll('.goa-checkbox')[0];
          //(chkBx as HTMLElement).style.minHeight = '0';
          //(chkBx as HTMLElement).style.marginTop = '0';
          if(chkBx){
            (chkBx as HTMLElement).style.display = 'table-cell';
          }
        }
      }
    }//acc
    // make preview list width same as search input
    var elem = page.querySelectorAll('[slot="trailingContent"]')[0];
    if(elem){
      (elem as HTMLElement).style.backgroundColor = 'transparent';
      (elem as HTMLElement).style.borderWidth = '0px';
    }

    var wrap = searchWrap.current;
    if (wrap !== undefined && wrap instanceof HTMLDivElement) {
      const w = wrap.clientWidth;
      // get search Inputfield position
      if (previewULWrapElement.current) {
        //if (previewULWrapElement.current!.style.visibility === '' || previewULWrapElement.current!.style.visibility === 'hidden') {
        previewULWrapElement.current!.style.width = `${Math.floor(w)}px`;
        previewULWrapElement.current!.style.minWidth = `${Math.floor(w)}px`;
        //}
      }
      //var inp = searchWrap.current.getElementsByTagName('goa-input')[0];
    }
  }
  /***************************************************************************************
   * 
   * @param search 
   * @param who 
  ***************************************************************************************/
  function loadRoles(search: string, who: string) {
    console.log('loadRolesAll ');
    setIsLoading("Loading roles");
    apiService.getRolesAll(auth.user?.access_token as string, search, props.currUser.userId).then((res: RolePlanInfo[]) => {
      if (res != null && Array.isArray(res)) {
        console.log('dRoles array size '+ res.length);
        // init .. good for all calalers
        res.forEach((role) => {
          role.selected = isRoleEditable(role, 'main') ? false : true; // check the checkBox for the preSelected and disabled ones
          role._grantedStart = role.grantedStart;
          role._grantedEnd = role.grantedEnd;
          role._grantedStartError = '';
          role._grantedEndError = '';
        });


        // who is the caller?
        if (who === 'preview') {
          setRolesListPreview(res);
          setShowPreview(res.length !== 0);
        }
        else if (who === 'main') {
          // hide the search Preview
          setShowPreview(false);
          setRolesMainList(res);
        }
        else if (who === 'granted') {
          // extract just the granted ones
          const gr = extractGrantedList(res);
          setRolesGrantedList(gr);
          props.setRolesCount( gr.length);
        }
      } else {
        // somethnig wrong
        setRolesListPreview([]);
        setRolesMainList([]);
        setShowPreview(false);
      }
    })
    .catch(error => {
      handleToastOpen("An error occured while loading data.", "emergency");
      setIsLoading('');
    })
    .finally(() => { setIsLoading('') });
  }
  /***************************************************************************************
   * whenever we update the main list, we have to update the Granted List
   * @param _roles
  ****************************************************************************************/
  function extractGrantedList(_roles: RolePlanInfo[]): RolePlanInfo[] {
    // extract Granted Roles
    const grRoles = _roles.filter((role) => {
      const rt = wdpUtil.getRoleTyle(role);
      return (rt === RoleType.current || rt === RoleType.future || rt === RoleType.graceExp);
    });
    return grRoles;
  }
  /***************************************************************************************
   * 
   *
  ****************************************************************************************/
  function populateGrantedtable(): any[] {

    if (RolesGrantedList == null || !Array.isArray(RolesGrantedList) || RolesGrantedList.length === 0) {
      return []
    }
    return (
      RolesGrantedList.map((role, i) => {
        const canEdit = isRoleEditable(role, 'granted') && role.isRoleAdmin;
        return (
          <tr key={'g' + i + role.roleId} className={!canEdit ? 'disabled' : ''}>
            <td>
              <span>{role.name}</span>
              <span><GoAIconButton variant="dark" icon="help-circle" size='large' title={role.description} onClick={() => OnClickRoleDesc()} /></span>
            </td>
            <td>
              {populateDateTD(role, 'granted', 'grantedStart')}
            </td>
            <td>
              {populateDateTD(role, 'granted', 'grantedEnd')}
            </td>
            {/* <td>{getRoleTyle(role)}</td>   */}
          </tr>
        )
      }) //map
    );//return
  }

  /*****************************************************************************************
   * 
   * @param _users 
   * @returns 
  ******************************************************************************************/
  function populateDateTD(role: RolePlanInfo, listName: string, fieldName: string) {

    let gStr = (fieldName === 'grantedStart') ? role._grantedStart : role._grantedEnd;
    
    // if(gStr !== null ){
    //    gStr = gStr.split('T')[0];
    //    gStr = gStr.replaceAll('-','/');
    //    console.log(gStr);
    //  }
    // const gDt = (gStr ? new Date(gStr) : null);
    // gDt?.setHours(0,0,0,0);
    let gDt = (gStr? dayjs(gStr).tz() : null);
    // this is to check if the there if a diff betwween utc and zoned ( because of DST)
    if(gDt != null ){
      if(wdpUtil.isDST( gStr)){
        gDt = gDt.add(1,'day');
      }
    }
    const gErr  = (fieldName === 'grantedStart') ? role._grantedStartError : role._grantedEndError;
    const cond  = listName === 'granted' ? editModeGranted : role.selected;
    return (
      <>
        {(() => {
          if (cond && isRoleEditable(role, listName)) {
            return (
              <div className={gErr ? 'top-align dt-with-error' : 'top-align'}>
                <CustomFmF 
                  name={`role.${fieldName}`}
                  value={gDt} 
                  onChange={(event:any) => onDateChange(event.target.value, `${listName}.${fieldName}`, role)} 
                  onBlur={(val: any) => { }} 
                  error={gErr}
                />
                {gErr ? <div className="error-div"><GoAIcon type="warning" theme="filled" size="small" />{gErr}</div> : ""}
                </div>
              )
          }
          else {
            return (<span>{wdpUtil.formatDate(gStr)}</span>)
          }
        })()}
      </>
    );
  }
  /*****************************************************************************************
   * 
   * @param _users 
   * @returns 
  ******************************************************************************************/
  function populateRolesMaintable(_users: Array<RoleInfo>): any[] {

    if (RolesMainList == null || !Array.isArray(RolesMainList) || RolesMainList.length === 0) {
      return []
    }
    return (
      RolesMainList.map((role, i) => {
        const canEdit = isRoleEditable(role, 'main') && role.isRoleAdmin;
        return (
          <tr key={'a' + i + role.roleId} className={!canEdit ? 'disabled' : ''}>
            <td>
              <span>
                {/* {getRoleTyle(role)}  {isRoleEditable(role,'main')?'T':'F'}      */}
                <GoACheckbox name={role.roleId} value={role.roleId}
                  checked={role.selected === true}
                  onChange={(name, checked, value) => {
                    onMainSelectRole(name, checked, value);
                  }}>
                </GoACheckbox>
              </span>
            </td>
            <td>
              <span>{role.name}</span>
              <span><GoAIconButton variant="dark" icon="help-circle" size='large' title={role.description} onClick={() => OnClickRoleDesc()} /></span>
            </td>
            <td>
              {populateDateTD(role, 'main', 'grantedStart')}
            </td>
            <td>
              {populateDateTD(role, 'main', 'grantedEnd')}
            </td>
            {/* <td>{(!isRoleEditable(role) && role.selected === true)?1:0}{getRoleTyle(role)}</td>   */}
          </tr>
        )
      }
      ) //map
    );//return
  }
  /*****************************************************************************************
   * 
  ******************************************************************************************/
  function isRoleEditable(myrole: RolePlanInfo, listName: string) {
    const rt = wdpUtil.getRoleTyle(myrole);
    if (listName === 'main') {
      return (rt === RoleType.available || rt === RoleType.expired);
    }
    if (listName === 'granted') {
      return (rt === RoleType.current || rt === RoleType.future || rt === RoleType.graceExp);
    }
  }
  /*****************************************************************************************
   * 
  ******************************************************************************************/
  function onDateChange(value: Dayjs | null, who: string, _role: RolePlanInfo) {
    //console.log(value);
    if ((value !== null) && isNaN(value.toDate().getTime())) {
      // Invalid Date
      value = null;
    }
    // who is calling ?
    const [listName, fieldName] = who.split('.');

    // find the role in the list
    const curworkList: RolePlanInfo[] = (listName === 'main') ? wdpUtil.deepCopy(RolesMainList) : wdpUtil.deepCopy(RolesGrantedList);
    const FoundRole = curworkList.find((role: RolePlanInfo) => role.roleId === _role.roleId);

    if (wdpUtil.isNullorUndefined(FoundRole)) {
      console.log("An internal error has occurred. Please contact support. Error Code 101");
      return;
    }
    //
    // update the role
    //
    if (fieldName === 'grantedStart') {
      FoundRole!._grantedStart = value ? dayjs(value).tz().startOf('day').toISOString(): null;
      // you cant have endDate is StartDate is null
      if (value === null) {
        FoundRole!._grantedEnd = null;
      }
      // reset the prev-error
      FoundRole!._grantedStartError = '';

    }
    else if (fieldName === 'grantedEnd') {
      FoundRole!._grantedEnd = value ? dayjs(value).tz().startOf('day').toISOString(): null;
      // reset the prev-error
      FoundRole!._grantedEndError = '';
    }
    // reasdy to update the state ..this will refected in the gui
    if (listName === 'main') {
      setRolesMainList(curworkList);
    }
    else if (listName === 'granted') {
      setRolesGrantedList(curworkList);
    }
  }

  

  /*****************************************************************************************
   * 
  ******************************************************************************************/
  /*function updateRoleState(listName:string,_role:RolePlanInfo){
    const newArr:RolePlanInfo[] = wdpUtil.deepCopy(listName === 'main'? RolesMainList : RolesGrantedList );
    let foundIt = newArr.find((role) => role.roleId === _role.roleId);
    if (!wdpUtil.isNullorUndefined(foundIt)) {
      foundIt = wdpUtil.deepCopy(_role);
      (listName === 'main')? setRolesMainList(newArr) : setRolesGrantedList(newArr);
    }
  }*/

  /*****************************************************************************************
   * 
  ******************************************************************************************/
  function onMainSelectRole(_roleID: string, checked: boolean, value: string) {

    const newArr: RolePlanInfo[] = wdpUtil.deepCopy(RolesMainList);
    const foundIt = newArr.find((role) => role.roleId === _roleID);
    if (!wdpUtil.isNullorUndefined(foundIt)) {
      foundIt!.selected = checked;
      // whether  checked or unchecked we have to reset 
      foundIt!._grantedStartError = '';
      foundIt!._grantedEndError = '';
      // refectr on GUI
      setRolesMainList(newArr);
    }
  }
  /*****************************************************************************************
   * build two sets ..one for the new plans and one for update the existing ones?
  ******************************************************************************************/
  function onSaveChangesMain() {

    // take a copy
    const newData: RolePlanInfo[] = wdpUtil.deepCopy(RolesMainList);
    const updated = (role: RolePlanInfo) => (role.selected === true && isRoleEditable(role, 'main') && isRoleUpdated(role));

    // validation the changes (add error msg to each role (if needed))
    newData.forEach((role) => {
      if (updated(role)) {
        role._grantedStartError = isRoleDatesValid(role, `main.grantedStart`);
        role._grantedEndError = isRoleDatesValid(role, `main.grantedEnd`);
      }
    });
    // safe guard
    const thereIsErr = newData.filter((role) => (role._grantedStartError !== '' || role._grantedEndError !== ''));
    if (thereIsErr.length) {
      // show the errors and return
      setRolesMainList(newData);
      return;
    }
    // all good
    // no error ? get the changes and send them to the backend
    // build the list for the new Granted ones
    const CheckedRoles_available = newData.filter((role) => (updated(role) && (wdpUtil.getRoleTyle(role) === RoleType.available)));
    if (CheckedRoles_available.length) {
      CheckedRoles_available.forEach((role) => {
        role.grantedStart = role._grantedStart!;
        role.grantedEnd = role._grantedEnd;
        role.createdBy = parseToken(auth.user?.id_token as string)?.name;
        role.createdOn = dayjs().tz().toISOString();
      });
      // send the new Plans array
      apiService.createRolePlans(auth.user?.access_token as string, CheckedRoles_available).then((res: any) => {
        setIsLoading('Create role assignment');
        if (res !== null && res === 'created') {
          handleToastOpen('Role granted successfully');
          // reload main
          loadRoles(searchStr, 'main');
          // reload granted
          loadRoles('', 'granted');


        } 
      })
      .catch((error: AxiosError) => {
        var toasMsg = "An error occured while saving.";
        if(error.code === "ERR_BAD_REQUEST"){
          toasMsg = (error.response?.data || error.message).toString() as string;
        }
        handleToastOpen(toasMsg, "emergency");
      })
      .finally(() => setIsLoading(''));
    }

    //
    // build the list for updated ones
    //
    const CheckedRoles_expired = newData.filter((role) => (updated(role) && (wdpUtil.getRoleTyle(role) === RoleType.expired)));
    if (CheckedRoles_expired.length) {
      CheckedRoles_expired.forEach((role) => {
        role.grantedStart = role._grantedStart!;
        role.grantedEnd = role._grantedEnd;
        role.updatedBy = parseToken(auth.user?.id_token as string)?.name;
        role.updatedOn =  dayjs().tz().toISOString();
      });
      apiService.updateRolePlans(auth.user?.access_token as string, CheckedRoles_expired).then((res: any) => {
        setIsLoading('Update role assignment');
        if (res !== null && res === 'updated') {
          handleToastOpen('Roles updated successfully');
          // reload main
          loadRoles(searchStr, 'main');
          // reload granted
          loadRoles('', 'granted');
        } else {
          console.log("An internal error has occurred. Please contact support. Error Code 103");
        }
      })
      .catch(error => {
        let toasMsg = "An error occured while saving.";
        if(error.code === "ERR_BAD_REQUEST"){
          toasMsg = (error.response?.data || error.message).toString() as string;
        }
        handleToastOpen(toasMsg, "emergency");
      })
      .finally(() => setIsLoading(''));
    }

  }
  /*****************************************************************************************
   * 
  ******************************************************************************************/
  function onSaveChangesGranted() {

    // take a copy
    const newData: RolePlanInfo[] = wdpUtil.deepCopy(RolesGrantedList);
    const updated = (role: RolePlanInfo) => (isRoleEditable(role, 'granted') && isRoleUpdated(role));

    // validation the changes (add error msg to each role (if needed))
    newData.forEach((role) => {
      if (updated(role)) {
        role._grantedStartError = isRoleDatesValid(role, `granted.grantedStart`);
        role._grantedEndError = isRoleDatesValid(role, `granted.grantedEnd`);
      }
    });
    // safe guard
    const thereIsErr = newData.filter((role) => (role._grantedStartError !== '' || role._grantedEndError !== ''));
    if (thereIsErr.length) {
      // show the errors and return
      setRolesGrantedList(newData);
      return;
    }
    // all Good 
    // no error ? get the changes and send them to the backend
    const dirty = RolesGrantedList.filter((role) => updated(role)); //here is the bug
    dirty.forEach((role) => {
      role.updatedBy = parseToken(auth.user?.id_token as string)?.name;
      role.updatedOn =  dayjs().tz().toISOString();
      role.grantedStart = role._grantedStart;
      role.grantedEnd = role._grantedEnd;
    });

    // submit
    // 
    apiService.updateRolePlans(auth.user?.access_token as string, dirty).then((res: any) => {
      setIsLoading('Update role assignment');
      if (res !== null && res === 'updated') {
        // confirmation mesage
        handleToastOpen('Roles updated successfully');

        //return to the view mode
        onEditGranted(false);

        // reload main
        loadRoles(searchStr, 'main');
        // reload granted
        loadRoles('', 'granted');
      } else {
        console.log("An internal error has occurred. Please contact support. Error Code 104");
      }
    })
    .catch(error => {
      handleToastOpen("An error occured while saving.", "emergency");
    })
    .finally(() => setIsLoading(''));
  }
  /*****************************************************************************************
   * 
  ******************************************************************************************/
  function onCancelConfirmed() {
    setShowModal(ModalType.none);
    if (showModal === ModalType.mainRolesCancel) {
      discardChanges('main');
    }
    if (showModal === ModalType.grantedRolesCancel) {
      discardChanges('granted');
    }
  }
  /*****************************************************************************************
   * 
  ******************************************************************************************/
  function discardChanges(listName: string) {

    // take a copy
    const curworkList: RolePlanInfo[] = (listName === 'main') ? wdpUtil.deepCopy(RolesMainList) : wdpUtil.deepCopy(RolesGrantedList);

    curworkList.forEach((role: RolePlanInfo) => {
      if (role.selected === true && isRoleEditable(role, listName)) {
        role.selected = false;
        role._grantedStart = role.grantedStart;
        role._grantedEnd = role.grantedEnd;
        role._grantedStartError = '';
        role._grantedEndError = '';
      }
      return role;
    });

    // ready to update
    if (listName === 'main') {
      setRolesMainList(curworkList);
    }
    else if (listName === 'granted') {
      onEditGranted(false);
      setRolesGrantedList(curworkList);
    }
  }
  /*****************************************************************************************
   * return all the roles that checked by the user (not the disabled ones..those are not editable) 
  ******************************************************************************************/
  function getCheckedRoles() {
    const CheckedRoles = RolesMainList.filter((role) => (role.selected === true && isRoleEditable(role, 'main')));
    return CheckedRoles;
  }
  /*****************************************************************************************
   
  ******************************************************************************************/
  function getDiryRoles(listName: string) {
    let updatedRoles: RolePlanInfo[] = [];
    if (listName === 'main') {
      updatedRoles = RolesMainList.filter((role) => (role.selected === true && isRoleEditable(role, listName) && isRoleUpdated(role)));
    }
    if (listName === 'granted') {
      updatedRoles = RolesGrantedList.filter((role) => isRoleEditable(role, listName) && isRoleUpdated(role));

    }
    return updatedRoles;
  }

  /*****************************************************************************************
   * 
  ******************************************************************************************/
  function onEditGranted(edit: boolean) {
    setEditModeGranted(edit);
  }
  /*****************************************************************************************
   * 
  ******************************************************************************************/
  function OnClickRoleDesc() {

    //alert(e.target);
  }
  /*****************************************************************************************
   * 
  ******************************************************************************************/
  function onSearchInputChange(name: string, value: string) {
    if (searchStr === value) {
      // event without a change in the content (special characters, arrows, ...)
      return;
    }
    setSearchStr(value);
    if (value.length === 0) {
      onCloseSearchBtn();
    }
    else if ((value.length >= 3)) {
      loadRoles(value, 'preview');
    }
    else if ((value.length < 3)) {
      setRolesListPreview([]);
    }

  }
  /****************************************************************************************
   * 
  *****************************************************************************************/
  function onSearchBtn() {
    const temp = wdpUtil.deepCopy(rolesListPreview);
    setRolesMainList(temp);
    setShowPreview(false);
    setopenAccordionMain(true);

  }
  /****************************************************************************************
   * 
  *****************************************************************************************/
  function onSearchItemSelect(selRole: RolePlanInfo) {

    //const selectedItem = RolesMainList.filter((role) => role.roleId === roleId)
    //if(!wdpUtil.isNullorUndefined(selectedItem) && selectedItem.length === 1){
    if (!wdpUtil.isNullorUndefined(selRole)) {
      setSearchStr(selRole.name!);
      setRolesMainList([selRole]);
      // change the preview ..we need this just to calc the search res title 
      setRolesListPreview([selRole]);
      // close the preview
      setShowPreview(false);
      // open the Accordion
      setopenAccordionMain(true);
    }

  }
  /****************************************************************************************
   * 
  *****************************************************************************************/
  function onCloseSearchBtn() {
    setShowPreview(false);
    loadRoles("", 'main');
    setSearchStr('');
    setopenAccordionMain(false);
  }
  /****************************************************************************************
   * 
  *****************************************************************************************/
  function populateSearchResTitle() {
    if (searchStr) {
      // search preview visible ?  we dont reset this list becuase we use it to show thje length 'rolesListPreview'
      return (`Roles for '${searchStr}' (${rolesListPreview.length} roles)`);
    }
    else {
      return `All Roles (${RolesMainList.length} roles)`;
    }
  }
  /****************************************************************************************
   * 
  *****************************************************************************************/
  function handleKeyEvent(event: any) {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      onSearchBtn();
    }
  }
  /****************************************************************************************
   * 
  *****************************************************************************************/
  const handleToastOpen = (msg: string, type: GoACalloutType | undefined = "success") => {
    setToastMsg(msg);
    setToastType(type)
    setToastOpen(true);
  };
  /****************************************************************************************
   * 
  *****************************************************************************************/
  const handleToastClose = () => {
    setToastOpen(false);
  };
  /****************************************************************************************
   * 
  *****************************************************************************************/
  function populatePreviewList() {
    if (rolesListPreview == null || !Array.isArray(rolesListPreview) || rolesListPreview.length === 0) {
      return []
    }
    return (
      rolesListPreview.map((role, i) =>
        <li key={'p' + i + role.roleId} >
          <Link to={{}} onClick={() => onSearchItemSelect(role)}><span>{role.name}</span></Link>
        </li>
      )
    );
  }
  /**********************************************************************************
   *  // sort Data
   * @param sortBy 
   * @param sortDir 
  ***********************************************************************************/
  function sortRolesAll(sortBy: string, sortDir: number) {

    const _roles = wdpUtil.sortData(sortBy, sortDir, RolesMainList);
    //console.log(RolesMainList.map((r)=>r.selected));
    //console.log(_roles.map((r)=>r.selected));
    setRolesMainList(_roles);

  }
  function sortRolesGranted(sortBy: string, sortDir: number) {
    const _roles = wdpUtil.sortData(sortBy, sortDir, RolesGrantedList);
    setRolesGrantedList(_roles);
  }

  function isRoleUpdated(role: RolePlanInfo) {
    if (!wdpUtil.isNullorUndefined(role)) {
      //without time
      const gs = role.grantedStart ? role.grantedStart.split('T')[0] : null;
      const _gs = role._grantedStart ? role._grantedStart.split('T')[0] : null;

      const ge = role.grantedEnd ? role.grantedEnd.split('T')[0] : null;
      const _ge = role._grantedEnd ? role._grantedEnd.split('T')[0] : null;

      if ((gs !== _gs) || (ge !== _ge)) {
        return true;
      }
    }
    return false;
  }
  /***************************************************************************************
   * 
   * @param role 
   * @returns 
  /****************************************************************************************/
  function isRoleDatesValid(role: RolePlanInfo, who: string) {


    let retErorr = '';
    const [listName, fieldName] = who.split('.');
    //const todaytTimestamp = new Date().getTime();     

    const todayDt  = dayjs().tz().startOf('day');
    //samerconst gsDt = wdpUtil.isNullorUndefined(role._grantedStart) ? null : zonedTimeToUtc(startOfDay(new Date(role._grantedStart!)),tz);
    //samerconst geDt = wdpUtil.isNullorUndefined(role._grantedEnd) ? null : zonedTimeToUtc(startOfDay(new Date(role._grantedEnd!)),tz);
    
    const gsDt = wdpUtil.isNullorUndefined(role._grantedStart) ? null : dayjs(role._grantedStart!).tz().startOf('day');
    const geDt = wdpUtil.isNullorUndefined(role._grantedEnd) ? null : dayjs(role._grantedEnd!).tz().startOf('day');
    
    if (fieldName === 'grantedStart') {
      if (gsDt === null) {
        retErorr = " Granted start date cannot be empty. enter a date";
      }
      else if (gsDt.toDate() < todayDt.toDate()) {
        if (listName === 'main') {
          retErorr = " this date cant be in the past. it can be today or in the future";
        }
      }
      else if ((geDt !== null) && (geDt.toDate() < gsDt.toDate())) {
        retErorr = " Granted start date must be before or the same as granted end";
      }
    }
    if (fieldName === 'grantedEnd') {
      if (geDt !== null && (geDt.toDate() < todayDt.toDate())) {
        retErorr = " Granted end date must be today or in the future";

      } else if ((geDt !== null && gsDt !== null) && (geDt.toDate() < gsDt.toDate())) {
        retErorr = " Granted end date must be later than start date";
      }
    }
    
    // special case: allow 1 day before today ..this is like a workaround 
    // to tell the backend expire the role immediately upon Save.
    if (listName === 'granted' && geDt !== null) {
      var daysDifference = wdpUtil.daysDiff(geDt.toDate(),todayDt.toDate());
      console.log(daysDifference);
      if(daysDifference === 1){
        //dont do anything, and let the save continue
        retErorr = "";
      }
    }
    return retErorr;
  }
  // ##########################################################################################
  // main html templete
  // ##########################################################################################
  return (
    <div className='roles-content'>
      <section className='granted-section acc-section' data-key={"grantedAccordionSec"}>
        <div className="acc-toolbar">
          {(editModeGranted) ?
            <div>
                <GoAButton testId='ur-granted-save' type="tertiary" disabled={getDiryRoles('granted').length === 0} onClick={() => onSaveChangesGranted()}>Save</GoAButton>
                <GoAButton testId='ur-granted-cancel' type="tertiary" onClick={() => setShowModal(ModalType.grantedRolesCancel)}>Cancel</GoAButton>
            </div>
            : <GoAButton testId='ur-granted-edit' type="tertiary" disabled={RolesGrantedList.length === 0} onClick={() => onEditGranted(true)}>Edit</GoAButton>
          }
        </div>

        <GoAAccordion open={true} heading={`Granted Roles (${RolesGrantedList.length} roles)`} secondaryText="" >
          {(RolesGrantedList.length) ?
            <WdpTable id={'grantedTable'} tableHeaders={grantedRolesTableHeaders} populatetableData={populateGrantedtable} sortData={() => sortRolesGranted} />
            :
            <h3 style={{ paddingLeft: '18px' }}>No roles assigned yet. Search for appropriate roles below.</h3>
          }

        </GoAAccordion>
      </section>

      <section className='search-title-section'>
        <h3 className='search-title'>Search for Roles</h3>
      </section>

      <section className='search-section'>
        <div className="search-wrap" ref={searchWrap} onKeyDown={handleKeyEvent}>
          <GoAInputText
            width='60ch'
            name="search"
            placeholder="Search by role name"
            trailingContent={
              <div className='search-buttons-wrap'>
                {(searchStr === null || searchStr.length === 0) ?
                    <GoAIconButton  testId='ur-search-icon' icon="search" variant="dark" size="medium" disabled title="Search" onClick={function (): void { onSearchBtn() }} />
                  : 
                      <GoAIconButton testId='ur-search-icon' icon="search" variant="dark" size="medium" title="Search" onClick={function (): void { onSearchBtn() }} />
                }

                {(searchStr === null || searchStr.length === 0) ?
                  <div style={{ width: '26px' }}></div> :
                    <GoAIconButton testId='ur-search-close-icon' icon="close" variant="dark" size="medium" title="Close" onClick={function (): void { onCloseSearchBtn() }} />
                }
              </div>
            }
            onChange={function (name: string, value: string): void {
              onSearchInputChange(name, value)
            }}

            value={searchStr}
          />
          {showPreview ?
            <ul className='search-preview-wrap' ref={previewULWrapElement}>
              {
                populatePreviewList()
              }
            </ul> : <></>
          }

        </div>

      </section>
      <section className='all-roles-section acc-section' data-key={"mainAccordionSec"}>
        {
          (RolesMainList.length > 0) ?
            <div className="acc-toolbar">
                <GoAButton testId='ur-all-roles-save' type="tertiary" onClick={() => onSaveChangesMain()} disabled={(getCheckedRoles().length === 0 || getDiryRoles('main').length === 0)}>Save</GoAButton>
                <GoAButton testId='ur-all-roles-cancel' type="tertiary" onClick={() => setShowModal(ModalType.mainRolesCancel)} disabled={(getCheckedRoles().length === 0 || getDiryRoles('main').length === 0)}>Cancel</GoAButton>
            </div>
            :
            <></>
        }
        <GoAAccordion
          open={openAccordionMain}
          heading={populateSearchResTitle()} secondaryText="">
          {(RolesMainList.length && !isLoading) ?
            <WdpTable id={'allRolesTable'} tableHeaders={rolesMainTableHeaders} populatetableData={populateRolesMaintable} sortData={() => sortRolesAll} />
            :
            (!isLoading) && <h3 style={{ paddingLeft: '18px' }}>{`No roles found for '${searchStr}'. Try searching for another role.`}</h3>
          }

        </GoAAccordion>
      </section>
      
      
      
      {/****** this part is for Modals ******/}
      <Toast timeout={2000} open={toastOpen} onClose={handleToastClose}>
        <GoACallout type={toastType} >
          <div className="toastContent">
            <span>{toastMsg}</span>
            <GoAIconButton icon="close" variant="dark" onClick={() => {
              handleToastClose();
            }}>
            </GoAIconButton>
          </div>
        </GoACallout>
      </Toast>
      {/* Modals section  */}
      <GoAModal heading="" open={(showModal === ModalType.mainRolesCancel || showModal === ModalType.grantedRolesCancel)} maxWidth="30vw"
        actions={
          <GoAButtonGroup alignment="end">
            <GoAButton testId='ur-success-modal-no' type='secondary' onClick={() => { setShowModal(ModalType.none); }}>No, continue editing</GoAButton>
            <GoAButton testId='ur-success-modal-yes' type='primary' onClick={() => { onCancelConfirmed(); }}>Yes, I am sure</GoAButton>
          </GoAButtonGroup>
        }
      >
        <p>Are you sure you want to discard the changes?</p>
      </GoAModal>

      {/****** END of Modals ******/}
      {/* Circular Progress as a layer  must be child of the page div */}
      {(isLoading) && <GoACircularProgress message={isLoading} variant="fullscreen" size="large" visible={true} />  }
    </div>
  );
}
